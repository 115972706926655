<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeEletivos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Vínculo de Eletivos
        </h1>
      </v-col>
    </v-row>

    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <!-- Quero poder selecionar várias matrpiculas de uma vez -->
          <v-col class="pt-0 pb-0" :cols="12" :md="6">
            <ValidationProvider name="Matriculas" rules="required" v-slot="{ errors }">
              <e-label>Aluno(s)</e-label>
              <e-autocomplete
                :items="matriculasList"
                :item-value="(item) => item.id"
                :item-text="(item) => `${item.id} | ${item.nomeAluno} — ${item.nomeTurma}`"
                :error-messages="errors"
                :loading="carregandoAlunos"
                v-model="form.matricula_ids"
                label="Selecione uma ou mais opções"
                multiple
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" :cols="12" :md="6">
            <ValidationProvider name="Professor" rules="required" v-slot="{ errors }">
              <e-label>Professor</e-label>
              <e-autocomplete
                :items="professoresList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => `${val.cpf} - ${val.nome}`"
                :error-messages="errors"
                :loading="carregandoProfessores"
                v-model="form.instrutor_id"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
        </v-row>

        <v-row
          class="animate__animated animate__zoomIn"
          style="animation-duration: 0.5s"
          v-for="relacao of relacoes"
          :key="relacoes.indexOf(relacao)"
        >
          <v-col class="pt-0 pb-0" :cols="12" :md="6">
            <ValidationProvider name="Disciplina" rules="required" v-slot="{ errors }">
              <e-label>Disciplina</e-label>
              <e-autocomplete
                :items="disciplinasList"
                :return-object="false"
                :item-value="(value) => value.id"
                :item-text="(val) => val.descricao"
                :error-messages="errors"
                :loading="carregandoDisciplinas"
                v-model="form.disciplina_id"
                :disabled="editing"
                label="Selecione uma opção"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>

          <v-col class="pt-0 pb-0" :cols="12" :md="6">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <ValidationProvider
                  name="Carga anual"
                  rules="required|regex:^[0-9]+$"
                  v-slot="{ errors }"
                >
                  <e-label>Carga horária</e-label>
                  <v-text-field
                    :error-messages="errors"
                    v-bind="attrs"
                    v-on="on"
                    v-model="form.ch_anual_disciplina"
                    label="200 (Apenas números)"
                    dense
                    solo
                  />
                </ValidationProvider>
              </template>
              <span>Carga horária anual da disciplina</span>
            </v-tooltip>
          </v-col>

          <v-col class="pt-0 pb-0" cols="1">
            <v-btn
              v-if="relacoes.length > 1"
              class="mt-7"
              color="error"
              @click="() => (relacoes = relacoes.filter((relacaoObj) => relacaoObj !== relacao))"
              :disabled="submittingForm"
              ><v-icon small>fa-trash</v-icon></v-btn
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              block
            >
              <v-icon left>mdi-send</v-icon>
              <!-- Ícone de envio -->
              Enviar Dados
            </v-btn>
            <!-- <v-btn
                class="ml-3"
                v-if="!editing"
                color="secondary"
                @click="adicionarRelacao"
                :disabled="submittingForm"
              >
                Adicionar
              </v-btn> -->
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
import Matricula from "@/Models/Matricula";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    this.loadData();
  },

  data() {
    return {
      horariosList: [],
      submittingForm: false,
      professoresList: [],
      turmasList: [],
      // form: {},
      form: {
        matricula_ids: [],
      },
      relacoes: [{}],
      disciplinasList: [],
      carregandoProfessores: false,
      carregandoAlunos: false,
      // carregandoTurmas: false,
      carregandoDisciplinas: false,
      camposObrigatoriosNaoPreenchidos: false,
      // relacoesDiasHorarios: [],
      // relacoesDiasHorariosEdit: [],
      horario_selecionado_id: null,
      dia_da_semana: null,
      dialogVisible: false,
      diasDaSemana: [
        {
          id: 0,
          descricao: "Segunda",
        },
        {
          id: 1,
          descricao: "Terça",
        },
        {
          id: 2,
          descricao: "Quarta",
        },
        {
          id: 3,
          descricao: "Quinta",
        },
        {
          id: 4,
          descricao: "Sexta",
        },
        {
          id: 5,
          descricao: "Sábado",
        },
      ],
      matriculasList: [],
    };
  },
  methods: {
    adicionarRelacao() {
      const ultimaRelacao = this.relacoes[this.relacoes.length - 1];
      this.relacoes.push({ ...ultimaRelacao });
    },

    async submitForm() {
      this.submittingForm = true;

      try {
        const formData = {
          ...this.relacoes[0],
          instrutor_id: this.form.instrutor_id,
          matriculas: this.form.matricula_ids,
          disciplina_id: this.form.disciplina_id,
          ch_disciplina: this.form.ch_anual_disciplina,
        };

        if (this.editing) {
          // Atualizar
          await this.$services.gestoesDeEletivosService.atualizar(formData);
          this.$toast.success("Vínculo atualizado com sucesso!");
        } else {
          // Criar
          const response = await this.$services.gestoesDeEletivosService.criar(formData);
          if (response.data) {
            this.relacoes = response.data; // Atualizando com os dados retornados da criação
            this.$toast.success("Vínculo(s) criado(s) com sucesso!");
          }
        }

        // Redireciona para a lista de gestões de eletivos
        this.$router.push({ name: "gestoesDeEletivos" });
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },

    async loadData() {
      try {
        this.$loaderService.open("Carregando dados");
        this.professoresList = await this.$services.professoresService.syncAll();

        // this.turmasList = await this.$services.turmasService.syncAll();
        this.disciplinasList = await this.$services.disciplinasService.syncAll();
        // this.horariosList = await this.$services.horariosService.syncAll();
        // this.matriculas = await this.$services.matriculasService.
        this.matriculasList = await this.$services.matriculasService.all();
        // console.log(this.matriculasList);

        // this.matriculasList = response.data.map((matricula) => new Matricula(matricula));
        if (this.editing) {
          try {
            const vinculo = await this.$services.gestoesDeEletivosService.gestaoGeralId(
              parseInt(this.$route.params.gestaoDeEletivosId, 10)
            );
            if (vinculo) {
              this.form.instrutor_id = vinculo.instrutor_id;

              this.form.disciplina_id = vinculo.disciplina_id;
              this.form.ch_anual_disciplina = vinculo.carga_horaria;
              this.form.matricula_ids = JSON.parse(vinculo.matricula_id);
              this.relacoes = [vinculo];
            }
          } catch (error) {
            this.$handleError(error);
          }
        }
        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
