<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn color="primary" x-small text @click="goBack">
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Diário de Eletivos
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between">
          <v-btn
            color="success"
            @click="criarGestaoDeEletivos"
            class="mr-2"
            v-show="$can(['gestoesDeAulas.criar', 'gestoesDeAulas.*'])"
            >Criar Vínculo</v-btn
          >
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Pesquisar gestão"
            single-line
            hide-details
            dense
            solo
          ></v-text-field>
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="gestoesDeEletivosList"
          :loading="carregandoGestoesDeEletivos"
          :items-per-page="10"
          hide-default-footer
          no-data-text="Não foram encontrados vínculos para você, por favor entre em contato com a secretaria/coordenação para que lhe adicionem acesso às turmas e disciplinas"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="
                  gestaoDeEletivos.estaDeletado
                    ? ['deleted']
                    : parseInt(gestaoDeEletivos.notifiq, 10) === 1
                    ? 'green lighten-5'
                    : ''
                "
                v-for="gestaoDeEletivos of items"
                :key="gestaoDeEletivos.id"
              >
                <td>{{ gestaoDeEletivos.id }}</td>
                <td width="15%">
                  {{
                    gestaoDeEletivos.escola_descricao ? gestaoDeEletivos.escola_descricao : "- - -"
                  }}
                </td>

                <td>
                  {{ gestaoDeEletivos.nomeDisciplina ? gestaoDeEletivos.nomeDisciplina : "- - -" }}
                </td>
                <td width="25%">
                  {{ gestaoDeEletivos.nomeProfessor ? gestaoDeEletivos.nomeProfessor : "- - -" }}
                </td>
                <td><e-resumo-aulas-snippet :aulas="gestaoDeEletivos.aulas" /></td>
                <td width="7%">
                  <div class="text-center">
                    <v-menu :close-on-content-click="false" :nudge-width="100" offset-x>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn x-small color="blue" class="white--text" v-bind="attrs" v-on="on">
                          AÇÕES
                        </v-btn>
                      </template>

                      <v-card class="mx-auto" max-width="300">
                        <v-list>
                          <v-list-item>
                            <v-list-item-avatar color="primary">
                              <v-list-item-title style="color: #fff">
                                {{
                                  gestaoDeEletivos.disciplina.descricao
                                    ? gestaoDeEletivos.disciplina.descricao
                                        .substring(0, 1)
                                        .toUpperCase()
                                    : "- - -"
                                }}
                              </v-list-item-title>
                            </v-list-item-avatar>

                            <v-list-item-content>
                              <v-list-item-title>
                                {{
                                  gestaoDeEletivos.nomeProfessor
                                    ? gestaoDeEletivos.nomeProfessor
                                    : "- - -"
                                }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{
                                  gestaoDeEletivos.nomeTurma ? gestaoDeEletivos.nomeTurma : "- - -"
                                }}
                                /
                                {{
                                  gestaoDeEletivos.nomeDisciplina
                                    ? gestaoDeEletivos.nomeDisciplina
                                    : "- - -"
                                }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-divider></v-divider>

                        <v-list>
                          <!-- item -->
                          <!-- <v-list-item-group v-if="!gestaoDeEletivos.estaDeletado" color="primary">
                            <v-list-item
                              :to="{
                                name: 'notas.lancarNotas',
                                params: { gestaoDeEletivosId: gestaoDeEletivos.id },
                              }"
                            >
                              <v-list-item-icon>
                                <v-icon small>fa fa-file-alt</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Notas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group> -->
                          <!-- item -->

                          <!-- item -->
                          <v-list-item-group color="primary">
                            <v-list-item @click="() => listarAulas(gestaoDeEletivos)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-check</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Confirmações de aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <v-list-item-group color="primary">
                            <v-list-item @click="() => verAulas(gestaoDeEletivos)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-eye</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <v-list-item-group
                            v-if="user.roles[0].name !== 'professor'"
                            color="primary"
                          >
                            <v-list-item @click="() => editarGestaoDeAula(gestaoDeEletivos)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-edit</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Editar Gestão de eletivos</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                          <!-- item -->
                          <!-- <v-list-item-group color="primary">
                            <v-list-item @click="capaDiarioClasse(gestaoDeEletivos.id)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-book-open</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Capa de diário de classe</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group> -->
                          <!-- item -->
                          <!-- <v-list-item-group color="primary"> -->
                          <!-- <v-menu open-on-hover left offset-x> -->
                          <!-- <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-book</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Planos</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template> -->
                          <!-- Plano bimestral -->
                          <!-- <v-list> -->
                          <!-- <v-list-item
                                  v-if="!gestaoDeEletivos.estaDeletado"
                                  :to="{
                                    name: 'gestoesDeAulas.newEditplanoBimestralInfantil',
                                    params: { gestaoDeAulaId: gestaoDeEletivos.id },
                                  }"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-book</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>PLANO BIMESTRAL INFANTIL</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item> -->

                          <!-- <v-list-item
                                  v-else
                                  :to="{
                                    name: 'gestoesDeAulas.planoBimestralCreate',
                                    params: { gestaoDeAulaId: gestaoDeEletivos.id },
                                  }"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-book</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>PLANO BIMESTRAL</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item> -->
                          <!-- Plano anual -->

                          <!-- <v-list-item
                                  v-if="!gestaoDeEletivos.estaDeletado"
                                  :to="{
                                    name: 'gestoesDeAulas.newEditplanoAnualInfantil',
                                    params: { gestaoDeAulaId: gestaoDeEletivos.id },
                                  }"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-book</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>PLANO ANUAL INFANTIL</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item> -->

                          <!-- <v-list-item
                                  v-else
                                  :to="{
                                    name: 'gestoesDeAulas.newEditplanoAnual',
                                    params: { gestaoDeAulaId: gestaoDeEletivos.id },
                                  }"
                                >
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-book</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>PLANO ANUAL</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item> -->
                          <!-- </v-list> -->
                          <!-- </v-menu> -->
                          <!-- </v-list-item-group> -->
                          <!-- <v-list-item-group v-if="!gestaoDeEletivos.estaDeletado" color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      >Gerar plano bimestral infantil</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="gerarPlanoBimestralInfantil(gestaoDeEletivos.id, item)"
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group> -->
                          <v-list-item-group v-else color="primary">
                            <v-menu open-on-hover left offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title>Gerar plano bimestral</v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="(item, index) in $constants.bimestres"
                                  :key="index"
                                >
                                  <v-list-item-title
                                    @click="gerarPlanoBimestral(gestaoDeEletivos.id, item)"
                                  >
                                    {{ item.desc }}
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-list-item-group>
                          <!-- planos anuais -->
                          <v-list-item-group v-if="!gestaoDeEletivos.estaDeletado" color="primary">
                            <!-- <v-menu>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      @click="gerarPlanoAnualInfantil(gestaoDeEletivos.id)"
                                      >Gerar plano anual infantil
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-menu> -->
                          </v-list-item-group>
                          <v-list-item-group color="primary" v-else>
                            <v-menu>
                              <template v-slot:activator="{ on, attrs }">
                                <v-list-item v-bind="attrs" v-on="on">
                                  <v-list-item-icon>
                                    <v-icon small>fa fa-file-pdf</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title @click="gerarPlanoAnual(gestaoDeEletivos.id)"
                                      >Gerar plano anual
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </template>
                            </v-menu>
                          </v-list-item-group>

                          <!-- item -->
                          <!-- <v-list-item-group color="primary">
                            <v-list-item @click="() => recuperarGestaoDeAula(gestaoDeEletivos)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-sync</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Recuperar Gestão de aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group> -->
                          <!-- item -->
                          <v-list-item-group
                            v-if="user.roles[0].name !== 'professor'"
                            color="primary"
                          >
                            <v-list-item @click="() => deletarGestaoDeEletivos(gestaoDeEletivos)">
                              <v-list-item-icon>
                                <v-icon small>fa fa-trash</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Apagar Gestão de aulas</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-card>
                    </v-menu>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-select-months-modal
          @dialogChange="dialog = $event"
          :dialog="dialog"
          confirmText="Gerar plano"
          cancelText="Fechar"
          @changeAction="gerarPlanoMensalInfantil"
        >
        </e-select-months-modal>

        <div v-if="dialogConfirmarAulas">
          <e-dialog-search-aulas-interval-date
            :gestao_selected_to_interval="gestao_selected_to_interval"
            :dialogConfirmarAulas="dialogConfirmarAulas"
            @dialogAulasChange="dialogConfirmarAulas = $event"
          >
          </e-dialog-search-aulas-interval-date>
        </div>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarGestoesDeEletivos"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import GestaoDeEletivo from "@/Models/GestaoDeEletivo";

export default {
  data() {
    return {
      gestoesDeEletivosList: [],
      carregandoGestoesDeEletivos: false,
      dialogConfirmarAulas: false,
      dialog: false,
      gestao_selected: null,
      dialogProgressAulas: false,
      gestao_selected_to_interval: null,
      search: "",
      pageNumber: 0,
      gestaoDeEletivos: null,

      tab: null,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Escola", value: "nomeFranquia" },
          // { text: "Turma", value: "nomeTurma" },
          { text: "Disciplina", value: "nomeDisciplina" },
          { text: "Professor", value: "nomeProfessor" },
          { text: "Aulas", value: "" },
          {
            text: "",
            value: "actions",
            sortable: false,
            width: "200px",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  async mounted() {
    this.$loaderService.open("Carregando gestões de eletivos");
    try {
      await this.listarGestoesDeEletivos(this.pageNumber);
    } catch (error) {
      this.$handleError(error);
    }
    this.$loaderService.close();
  },
  watch: {
    carregandoGestoesDeEletivos(value) {
      if (value && this.gestoesDeEletivosList.length === 0) {
        this.$loaderService.open("Carregando gestões de eletivos");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      this.carregandoAvisos = !this.carregandoAvisos;
      if (val.length > 2) {
        await this.listarGestoesDeEletivos(0, val);
      }
      if (!val) {
        await this.listarGestoesDeEletivos(0);
      }
      this.carregandoAvisos = !this.carregandoAvisos;
    },

    quantidades_confirmadas(val) {
      if (val < 100) {
        return;
      }
      if (this.quantidades_confirmadas >= 100) {
        clearInterval(this.interval);
        this.dialogProgressAulas = false;
        this.$toast.success("Aulas confirmadas com sucesso");
        return;
      }
      this.quantidades_confirmadas = 0;
      this.bufferValue = 10;
      this.startBuffer();
    },
  },

  methods: {
    async listarAulas(gestaoDeEletivo) {
      this.gestao_selected_to_interval = gestaoDeEletivo;
      this.dialogConfirmarAulas = true;
    },
    openModalSelectMonths(gestaoDeEletivoId) {
      this.gestao_selected = gestaoDeEletivoId;
      this.dialog = !this.dialog;
    },
    async gerarPlanoBimestral(gestaoId, item) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestral(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async gerarPlanoBimestralInfantil(gestaoId, item) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.planoBimestralInfantil(
          parseInt(gestaoId, 10),
          parseInt(item.value, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async gerarPlanoMensalInfantil(monthSelected) {
      this.$loaderService.open("Gerando plano de aula...");
      try {
        await this.$services.relatoriosService.planoMensalInfantil(
          parseInt(this.gestao_selected, 10),
          parseInt(monthSelected.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async capaDiarioClasse(gestaoId) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        await this.$services.relatoriosService.capaDiarioClasse(parseInt(gestaoId, 10));
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    goBack() {
      if (this.user.role.name.includes("professor")) {
        this.$router.push({ name: "acessoProfessor.home" });
      }
      this.$router.push({ name: "home" });
    },
    routePlanos(route, gestao) {
      this.$router.push({ name: route, params: { gestaoDeEletivoId: gestao } });
    },
    async verAulas(gestaoDeEletivo) {
      this.$router.push({
        name: "aulasEletivos",
        params: {
          gestaoDeEletivosId: gestaoDeEletivo.id,
        },
      });
    },
    async listarGestoesDeEletivos(pageNumber, query = null) {
      this.carregandoGestoesDeEletivos = true;
      try {
        const response = await this.$services.gestoesDeEletivosService.syncAll(pageNumber, query);
        this.gestoesDeEletivosList = await response.gestoes_de_eletivos.data.map(
          (gestao) => new GestaoDeEletivo(gestao)
        );
        this.paginate = response;
      } catch {
        this.carregandoGestoesDeEletivos = false;
      }
      this.carregandoGestoesDeEletivos = false;
    },
    criarGestaoDeEletivos() {
      this.$router.push({ name: "gestoesDeEletivos.create" });
    },
    editarGestaoDeAula(gestaoDeEletivos) {
      this.$router.push({
        name: "gestoesDeEletivos.edit",
        params: { gestaoDeEletivosId: gestaoDeEletivos.id },
      });
    },

    async deletarGestaoDeEletivos(gestaoDeEletivos) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta gestaoDeAulas?",
        confirmationCode: gestaoDeEletivos.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeEletivos.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando a GestaoDeAula");
              try {
                await this.$services.gestoesDeEletivosService.deletarGestaoDeEletivos(
                  gestaoDeEletivos
                );
                this.$toast.success("GestaoDeAula deletada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarGestaoDeAula(gestaoDeAulas) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta gestaoDeAulas?",
        confirmationCode: gestaoDeAulas.id,
        confirmationMessage: `Por favor, digite <strong>${gestaoDeAulas.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a GestaoDeAula");
              try {
                await this.$services.gestoesDeAulasService.recuperarGestaoDeAula(gestaoDeAulas);
                this.$toast.success("GestaoDeAula recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async gerarPlanoAnualInfantil(gestao_id) {
      this.$loaderService.open("Gerando plano anual infantil...");
      try {
        await this.$services.relatoriosService.planoAnualInfantil(parseInt(gestao_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async gerarPlanoAnual(gestao_id) {
      this.$loaderService.open("Gerando plano anual...");
      try {
        await this.$services.relatoriosService.planoAnual(parseInt(gestao_id, 10));
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>
<style></style>
